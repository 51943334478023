import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "category-table", fluid: "", tag: "section" } },
    [
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3 mb-5",
          attrs: { icon: "mdi-currency-usd", inline: "", color: "secondary" },
          scopedSlots: _vm._u([
            {
              key: "after-heading",
              fn: function () {
                return [
                  _c("h1", { staticClass: "h5" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("vehicle_title")))]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VBtn,
            {
              attrs: {
                "data-test": "Vehicle:Vehicle:BtnAddNewVehicle",
                color: "primary",
                rounded: "",
                absolute: "",
                fab: "",
                top: "",
                right: "",
              },
              on: { click: _vm.addVehicle },
            },
            [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
            1
          ),
          _c(
            VForm,
            {
              ref: "form",
              staticClass: "form-filter",
              attrs: { "lazy-validation": _vm.lazy },
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.submitFilter()
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                VRow,
                { staticClass: "mt-6", attrs: { justify: "center" } },
                [
                  _c(VTextField, {
                    staticClass: "mx-3",
                    attrs: {
                      dense: "",
                      outlined: "",
                      label: _vm.$t("search"),
                      "append-icon": "mdi-close",
                    },
                    on: { "click:append": _vm.handleCleanFilter },
                    model: {
                      value: _vm.nameFilter,
                      callback: function ($$v) {
                        _vm.nameFilter = $$v
                      },
                      expression: "nameFilter",
                    },
                  }),
                  _c(
                    VBtn,
                    {
                      staticClass: "mx-3",
                      attrs: {
                        disabled: _vm.filterLoading,
                        color: "secondary",
                        type: "submit",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("apply")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              !_vm.allLoaded
                ? _c("div", [
                    _vm.cleanVehicleData && _vm.cleanVehicleData.length > 0
                      ? _c(
                          "div",
                          [
                            _c(VSimpleTable, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v(_vm._s(_vm.$t("name")))]),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.$t("company_id"))),
                                  ]),
                                  _c("th", [_vm._v(_vm._s(_vm.$t("nire")))]),
                                  _c("th", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(_vm.$t("edit"))),
                                  ]),
                                  _c("th", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(_vm.$t("remove"))),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(
                                  _vm.cleanVehicleData,
                                  function (vehicle, index) {
                                    return _c(
                                      "tr",
                                      {
                                        key: index,
                                        attrs: {
                                          "data-test": "Vehicle:Vehicle:Teste",
                                        },
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(_vm._s(vehicle.name)),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              vehicle.cnpj ? vehicle.cnpj : "-"
                                            )
                                          ),
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              vehicle.nire ? vehicle.nire : "-"
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              VBtn,
                                              {
                                                staticClass:
                                                  "px-2 ml-1 secondary",
                                                attrs: {
                                                  "data-test":
                                                    "Vehicle:Vehicle:BtnEdit:" +
                                                    vehicle.name,
                                                  "min-width": "0",
                                                  small: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.ToEditVehicle(
                                                      vehicle
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  VIcon,
                                                  { attrs: { small: "" } },
                                                  [_vm._v("mdi-pencil")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              VTooltip,
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  color: "gray",
                                                  disabled:
                                                    vehicle.canBeDeleted,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function (ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "div",
                                                            _vm._g({}, on),
                                                            [
                                                              _c(
                                                                VBtn,
                                                                {
                                                                  staticClass:
                                                                    "px-2 ml-1",
                                                                  attrs: {
                                                                    disabled:
                                                                      !vehicle.canBeDeleted,
                                                                    "data-test":
                                                                      "Vehicle:Vehicle:BtnDelete:" +
                                                                      vehicle.name,
                                                                    color:
                                                                      "red",
                                                                    "min-width":
                                                                      "0",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.open_delete_dialog(
                                                                          vehicle
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    VIcon,
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-trash-can"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "can_be_deleted_vehicle"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                            _c(VPagination, {
                              attrs: {
                                color: "primary",
                                length: _vm.pageCount,
                              },
                              on: { input: _vm.changePage },
                              model: {
                                value: _vm.page,
                                callback: function ($$v) {
                                  _vm.page = $$v
                                },
                                expression: "page",
                              },
                            }),
                          ],
                          1
                        )
                      : _c("div", [
                          _c("p", { staticClass: "not-found-items" }, [
                            _vm._v(_vm._s(_vm.$t("not_found_filter"))),
                          ]),
                        ]),
                  ])
                : _c(VProgressCircular, {
                    staticStyle: {
                      "margin-left": "50%",
                      "margin-top": "32px",
                      "margin-bottom": "32px",
                    },
                    attrs: { indeterminate: "", size: "70", color: "primary" },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.delete_dialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.delete_dialog,
                callback: function ($$v) {
                  _vm.delete_dialog = $$v
                },
                expression: "delete_dialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: { obj: _vm.delete_obj, name: _vm.delete_obj.name },
                on: {
                  close: function ($event) {
                    _vm.delete_dialog = false
                  },
                  delete: _vm.delete_vehicle,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }